<template lang="pug">
  VueApexCharts(
    :options="chartOptions"
    :series="series"
  )
</template>

<script>
  import VueApexCharts from "vue-apexcharts"
  import { min, max } from "lodash-es"

  export default {
    props: {
      xValues: {
        type: Array,
        default: () => new Array()
      },
      yValues: {
        type: Array,
        default: () => new Array()
      },
      name: {
        type: String,
        default: "Value"
      },
      curve: {
        type: String,
        default: "straight"
      }
    },

    components: {
      VueApexCharts
    },

    computed: {
      minYValue() {
        return min(this.yValues)
      },

      maxYValue() {
        return max(this.yValues)
      }
    },

    data() {
      return {
        series: [
          {
            name: this.name,
            data: this.yValues
          }
        ],

        chartOptions: {
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            }
          },
          chart: {
            type: "line"
          },
          xaxis: {
            categories: this.xValues
          },
          yaxis: {
            min: () => this.minYValue,
            max: () => this.maxYValue
          },
          stroke: {
            curve: this.curve
          }
        }
      }
    }
  }
</script>

<style lang="sass" scoped></style>
